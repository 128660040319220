
import {
  RespondToSurveyMutationMutation,
  RespondToSurveyMutation,
  SurveyFragmentFragment,
  SurveyQuery,
  RespondToSurveyMutationMutationVariables,
} from "@/gql"
import { Vue, Component, Prop } from "vue-property-decorator"
import ScaleInput from "@/components/fields/ScaleInput.vue"
import SurveySingleChoiceRadio from "@/components/fields/SurveySingleChoiceRadio.vue"
import Icon from "@/components/widgets/icons/Icon.vue"

@Component({
  components: {
    ScaleInput,
    SurveySingleChoiceRadio,
    Icon,
  },
})
export default class SurveyQuestionnaire extends Vue {
  @Prop({ required: true })
  readonly surveyId!: string

  @Prop({ required: false, default: false })
  readonly timer!: string

  readonly surveyQuery = SurveyQuery
  surveyCompleted = false
  submitFailed = false
  loading = false
  loadingError = false
  networkError = false
  submitting = false
  survey: SurveyFragmentFragment | null = null
  currentQuestionIndex = 0
  answers: Record<string, any> = {}
  showSubmitDialog = false

  async mounted() {
    this.fetchSurveyAssigment()
  }

  async fetchSurveyAssigment() {
    try {
      this.loading = true

      // Fetch surveys from server
      const result = await this.$apollo.query({
        query: this.surveyQuery,
        variables: {
          filter: { idIn: this.surveyId },
        },
      })

      if (result.data && result.data.surveys) {
        this.loading = false

        if (result.data.surveys.data.length && result.data.surveys.data[0].public) {
          this.survey = result.data.surveys.data[0]
        }
      }

      if (result && result.errors) {
        this.loadingError = true
      }
    } catch (error) {
      this.networkError = true
    } finally {
      this.loading = false
    }
  }

  goBackToSurvey() {
    this.$router.go(0)
  }

  getParsedData(data: any) {
    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  }

  get questions() {
    return this.survey?.questions
  }

  get answeredQuestions() {
    return this.setupAnswer.filter((answer: any) => {
      return ![undefined, null, ""].includes(answer) && Array.isArray(answer)
        ? answer.length > 0
        : true
    })
  }

  get setupAnswer() {
    let answerHash: any[] = []

    for (const [key, value] of Object.entries(this.answers)) {
      answerHash.push({ question_id: key, choice: value })
    }

    return answerHash
  }

  get isAllQuestionsAnswered() {
    return this.answeredQuestions.length === this.questions?.length
  }

  async submitSurvey() {
    if (this.survey!.prn) {
      this.showSubmitDialog = false

      this.surveyCompleted = true
      return
    }
    this.submitting = true

    const result = await this.mutate<
      RespondToSurveyMutationMutation,
      RespondToSurveyMutationMutationVariables
    >({
      mutation: RespondToSurveyMutation,
      variables: {
        surveyId: this.survey!.prn,
        response: { data: JSON.stringify(this.setupAnswer) },
        finalize: this.isAllQuestionsAnswered,
      },
      handlePayloadErrors: false,
      done: () => {
        this.submitting = false
      },
    })

    if (result?.errors) {
      this.addError(`Can't submit response for this survey`)
      return
    }

    if (result && result.data && result.data.respondToSurvey.error) {
      this.surveyCompleted = false
      this.submitFailed = true
    } else {
      this.surveyCompleted = true
      this.submitFailed = false
    }
  }

  get validateForm() {
    const requiredQuestions = this.questions!.filter((q) => q.required).map((x) => x.id)

    const answeredQuestionIds = this.setupAnswer.map((answer) => answer.question_id)

    const unansweredQuestionIds = requiredQuestions.filter(
      (questionId) => !answeredQuestionIds.includes(questionId)
    )
    return unansweredQuestionIds.length > 0
  }
}
