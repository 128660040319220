export const APPLICATION_TRACK_LS_KEY = "application-track-details"
export const APPLICANT_ID_KEY = "applicant-id-key"

export const gender = [
  {
    text: "Male",
    value: "male",
  },
  {
    text: "Female",
    value: "female",
  },
  {
    text: "Other",
    value: "other",
  },
]

export const FAQ = [
  {
    title: "What is AHEAD?",
    subtitle:
      "The AHEAD programme was launched to train conscious business leaders from across Africa that are excited about contributing to the private sector growth of our continent. Over a 3- to 4-month period, you will learn to think and operate like a top African business professional. The time commitment is ~40 hours per month.",
  },
  {
    title: "How long is the application process?",
    subtitle:
      " Applications are processed as soon as they come in and it may take up to a week for your application to be reviewed and considered",
  },
  {
    title: "How are my answers assessed?",
    subtitle:
      "Applications are processed as soon as they come in and it may take up to a week for your application to be reviewed and considered",
  },
  {
    title: "How are my answers assessed?",
    subtitle:
      "Applications are processed as soon as they come in and it may take up to a week for your application to be reviewed and considered",
  },
]
