var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_setup.activeStage)?_c('div',[_vm._v("Something went wrong!")]):_vm._e(),(_setup.activeStage)?_c('v-app',[_c(_setup.Nav,{class:{
        'custom-nav': _setup.isMobile(),
      },attrs:{"logoUrl":"/ahead_logo.svg","width":290,"temporary":_setup.isMobile()}},[_c('div',{staticClass:"mt-13"},_vm._l((_setup.stages),function(stage,index){return _c(_setup.CheckStep,{key:index,attrs:{"title":stage.name,"checked":_setup.completedSteps.includes(stage.anchor),"active":stage.anchor === _setup.activeStage.anchor}})}),1),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"px-4"},[_c('v-divider')],1),_c('div',{},[_c(_setup.NavItem,{attrs:{"name":"Help","icon":"info-circle","temporary":true,"active":_setup.helpStep.anchor === _setup.activeStage.anchor},on:{"to":function($event){return _setup.navigateToStep(_setup.helpStep)}}}),_c(_setup.NavItem,{attrs:{"name":"Logout","icon":"logout-2","temporary":true},on:{"to":_setup.logout}})],1)])]),_c('v-main',{staticClass:"main",class:{
        'main-container': _setup.isMobile(),
      },attrs:{"app":""}},[_c(_setup.Overview,{directives:[{name:"show",rawName:"v-show",value:(_setup.activeStage.anchor === 'overview'),expression:"activeStage.anchor === 'overview'"}],attrs:{"application-track":_setup.props.applicationTrack,"buttonLabel":_setup.overviewButtonLabel},on:{"startApplication":_setup.onStartApplication}}),_c(_setup.PersonalInfo,{directives:[{name:"show",rawName:"v-show",value:(_setup.activeStage.anchor === 'personal-information'),expression:"activeStage.anchor === 'personal-information'"}],attrs:{"applicant":_setup.applicant},on:{"save":_setup.onSavePersonalInfo}}),(['external_form', 'assessment'].includes(_setup.activeStage.type ?? ''))?_c(_setup.ApplicationStepPage,[_c(_setup.QuizIntro,{staticStyle:{"margin-top":"100px"},attrs:{"title":_setup.activeStage.name || '',"description":_setup.activeStage.description || '',"disabled":_setup.hasCompletedQuiz(_setup.activeStage.anchor),"showNext":_setup.hasCompletedQuiz(_setup.activeStage.anchor)},on:{"start":function($event){return _setup.onStartQuiz(_setup.activeStage.anchor)},"next":function($event){_setup.navigateToStep(_setup.stages[_setup.stages.findIndex((s) => s.anchor === _setup.activeStage.anchor) + 1])}}})],1):_vm._e(),_c(_setup.Review,{directives:[{name:"show",rawName:"v-show",value:(_setup.activeStage.anchor === 'review'),expression:"activeStage.anchor === 'review'"}],attrs:{"application-track":_setup.props.applicationTrack}}),_c(_setup.Help,{directives:[{name:"show",rawName:"v-show",value:(_setup.activeStage.anchor === 'help'),expression:"activeStage.anchor === 'help'"}]})],1),_c('Alerts')],1):_vm._e(),(_setup.showQuizModal)?_c(_setup.TakeAssessmentModal,{attrs:{"url":_setup.activeStage.externalFormUrl
        ? `${_setup.activeStage.externalFormUrl}?email=${_setup.applicant?.emailAddress}&name=${_setup.applicant?.name}`
        : null,"timer":_setup.activeStage.timer || undefined},on:{"close":_setup.handleAssessmentModalClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }